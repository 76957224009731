<template>
  <div class="flex w-full max-w-xs flex-col md:w-fit md:max-w-none md:flex-row">
    <div v-for="(button, idx) in data" :key="idx" :class="itemClass ? itemClass : ''">
      <Button
        :label="button.title"
        :to="
          button.pageLink !== null
            ? localePathByType(
                button.pageLink?.__typename as string,
                button.pageLink?.slug as string
              )
            : button.externalLink
        "
        :target="button.externalLink && '_blank'"
        :isType="button.primary ? 'primary' : 'secondary'"
        class="!block !w-full"
        :class="[idx === 1 ? 'mt-4 md:ml-8 md:mt-0' : 'md:mr-8', buttonClass ? buttonClass : '']"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { CallToActionFragment } from '~/types/generated'

const { localePathByType } = useLocalePathByType()

defineProps({
  data: {
    type: Array as PropType<CallToActionFragment[]>,
    default: () => []
  },
  itemClass: {
    type: String,
    default: ''
  },
  buttonClass: {
    type: String,
    default: ''
  }
})
</script>
